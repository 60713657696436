.ImageSection{
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
  .img{
    width: 100%;
    height: 55vh;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .about-info{
    margin-left: 2rem;
    h4{
      font-size: 2rem;
    }
    .about-text{
      padding: 1rem 0;
    }
  }
  @media screen and (max-width: 1330px) {
    flex-direction: column;
    .img{
      width: 70%;
      height: 40vh;
      @media screen and (max-width: 700px) {
          width: 90%;
      }
    }
    .about-info{
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.about-details{
  display: flex;
  p{
    padding: .3rem 0;
  }
  .right-section{
    margin-left: 2rem;
  }
}

.cv-btn-section{
  display: flex;
  column-gap: 2rem;
}

.cv-btn{
  padding: .8rem 1rem;
  background-color: #037FFF;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 1rem;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    width: 0%;
    height: .3rem;
    left: 0;
    bottom: 0;
    background-color: #a4acc4;
    transition: all 0.4s ease-in-out;
  }
  &:hover::after{
    width: 100%;
  }

}

.skills-section{
  margin: 0 0 4rem;
}
.skill-subsection{
  color: #c9c9c9;
  margin: 2.8rem 0 .8rem;
}
.skills-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 2rem;
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
  }
}