
.ShowPortfolio{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2.5rem;
  column-gap: 1.5rem;
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
   }
   @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
   }
  .portfolio-item{
    .image-data{
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 20px;
        left: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 40px);
        background-color: #9ccaf8;
        transform-origin: center;
        transform: scale(0);
        transition: all .3s ease-in-out;
        opacity: 0.6;
      }
      &:hover::before{
        transform: scale(1);
      }
      img{
        width: 100%;
        height: auto;
        // object-fit: cover;
      }
      .hover-items{
        list-style: none;
        position: absolute;
        top: 60%;
        left: 45%;
        transform: translate(-40%, -50%);
        // padding: 1rem 1rem;
        visibility: hidden;
        a{
          position: relative;
          display: block;
          text-decoration: none;
          font-family: inherit;
          font-size: 1rem;
          color: white;
          padding: 0 1rem .5rem .5rem;
          border-radius: 10px;
          background-color: #037FFF;
          img{
            position: relative;
            top: 6px;
            left: 5px;
            width: 1.8rem;
            height: 1.8rem;
          }
          &:not(:last-child){
            margin-bottom: .7rem;
          }
        }
      }
      &:hover{
        .hover-items{
          transition: all .6s ease-in-out .1s;
          visibility: visible;
          // top: 50%;
        }
      }
    }

    a{
      font-size: 1.6rem;
      font-weight: 400;
      text-decoration: none;
      color: white;
      transition: all .3s ease-in-out;
      &:hover{
        color: #037FFF;
        cursor: pointer;
      }
    }
    .date{
      font-size: .9rem;
    }
    .text{
      white-space: pre-wrap;
    }
  }
}

.Categories{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 8rem 3rem;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    margin: 1rem 3rem 3rem;
   }
  @media screen and (max-width: 600px) {
    margin: 1rem 1rem 2rem;
   }
  .btn-categories{
    padding: .6rem 2rem;
    background-color: #0381ff5b;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    color: white;
    margin-bottom: 1rem;
    transition: all .3s ease-in-out;
    &:not(:last-child){
      margin-right: 1rem;
    }
    &:hover{
      background-color: #037FFF;
    }
    &:focus{
      background-color: #037FFF;
    }

  }
}
