@import './styles/about';
@import './styles/contact';
@import './styles/homepage';
@import './styles/layout';
@import './styles/navbar';
@import './styles/portfolio';
@import './styles/services';
@import './styles/skill';
@import './styles/title';


html{
  font-size: 14px;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar{
  width: 8px;
}
body::-webkit-scrollbar-thumb{
  background-color: #0056d898;
  border-radius: 50px;
}
body::-webkit-scrollbar-track{
  background-color: #777;
}
body{
  color: #a4acc4;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 1.2rem;
}

span{
  color: #037FFF;
}

@media screen and (max-width: 1330px) {
  html{
    font-size: 13px;
  }
}

@media screen and (max-width: 1100px) {
  html{
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  html{
    font-size: 10px;
  }
}