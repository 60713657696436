.ContactItem{
  width: 100%;
  &:not(:last-child){
    margin-bottom: 1rem;
  }
  .contact{
    display: flex;
    align-items: center;
    padding: 1rem 0;
    background-color: #191D2B;
    .right-items{
      margin-left: 1rem;
      h6{
        font-size: 1.4rem;
        font-weight: 500;
      }
      p{
        padding-right: 1rem;
        img{
          padding: 0;
          width: 15rem;
          height: 2rem;
          margin-left: -.3rem;
          border: 0px;
        }
        a{
          color: inherit;
          font-size: 1rem;
        }
      }
    }
    img{
      padding: .5rem;
      width: 6rem;
      height: 6rem;
      margin-left: 2rem;
      border: 1px solid #2e344e;
    }
  }
  
}

.ContactPage{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 4rem;
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    .map-sect{
      width: 100%;
      height: 50vh;
      margin-bottom: 1rem;
    }
   }
  .contact-sect{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.map-sect{
  width: 97%;
  padding: .5rem;
  background-color: #191D2B;
  iframe{
    width: 100%;
    height: 100%;
  }
}

.title{
  margin-bottom: 5rem;
}