.App{
  .sidebar{
    width: 16%;
    height: 100vh;
    background-color: #191D2B;
    position: fixed;
    z-index: 11;
    border-right: 1px solid #2e344e;
    transform-origin: left;
    @media screen and (max-width: 1000px) {
      transition: all .4s ease-in-out;
      transform: translateX(-100%);
      width: 20%;
    }
    @media screen and (max-width: 420px) {
      width: 40%;
    }
  }
  .nav-toggle{
    @media screen and (max-width: 1000px) {
      transition: all .4s ease-in-out;
      transform: translateX(0);

    }
  }

  .main-content{
    width: 84%;
    margin-left: 16%;
    background-color: #10121B;
    min-height: 100vh;
    background-image: url(../images/dots.svg);
    display: grid;
    position: relative;
    .content{
      margin: 5rem 10rem;
      @media screen and (max-width: 1400px) {
        margin: 5rem 8rem;
      }
      @media screen and (max-width: 1000px) {
        margin: 4rem 6rem;
      }
      @media screen and (max-width: 700px) {
        margin: 2rem 3rem;
      }
      @media screen and (max-width: 400px) {
        margin: 2rem 2rem;
      }
    }
    @media screen and (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.nav-btn{
  position: fixed;
  z-index: 10;
  right: 8%;
  top: 8%;
  width: 4rem;
  height: 4rem;
  display: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .lines{
    height: .4rem;
    width: 100%;
    background-color: #3858cc;
    pointer-events: none;
    display: none;
    border-radius: 20px;
    &:not(:last-child){
      margin-bottom: .5rem;
    }
  }
  @media screen and (max-width: 1000px) {
    display: block;
    .lines{
      display: block;
    }
  }
}